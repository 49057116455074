import React from 'react'
import '../components/styles/search-tool.scss'
import Layout from '../components/common/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import { useEffect } from 'react'

export default function Products() {

    const data = useStaticQuery(graphql`
                query Search {
                allDataYaml(filter: {yamlId: {eq: "search"}}) {
                    nodes {
                        results {
                            label
                            url
                            keywords
                        }
                    }
                }
                }
            `),
            //  convert our results to lower case
            results = (() => {
                const r = data.allDataYaml.nodes[0].results
                    
                for(let i = 0, len = r.length; i < len; i++) {
                    const   result = r[i],
                            keywords = result.keywords
                    
                    for(let j = 0, jLen = keywords.length; j < jLen; j++) {
                        keywords[j] = keywords[j].toLowerCase()
                    }
                }

                return r
            })()

    useEffect(() => {
        const   searchBtn = document.getElementById('search-btn'),
                searchField = document.getElementById('search-field'),
                searchResults = document.getElementById('search-results'),
                searchErrors = document.getElementById('search-errors'),
                informationBtn = document.getElementById('information'),
                populateAlert = (alert) => {
                    searchErrors.innerHTML = alert
                    alert === '' ? searchErrors.classList.remove('show') : searchErrors.classList.add('show')
                },
                populateResults = (matches) => {
                    searchResults.innerHTML = ''
                    
                    for(let i = 0, len = matches.length; i < len; i++){
                        const   match = matches[i],
                                link = document.createElement('a'),
                                img = document.createElement('img'),
                                span = document.createElement('span')
                        
                        link.target = '_blank'
                        link.href = match.url
                        link.rel = 'noopener noreferrer'
                        img.src = '/svg/download-icon.svg'
                        img.alt = 'Download icon'
                        span.innerHTML = match.label
                        link.append(img, span)
                        searchResults.appendChild(link)
                    }
                },
                showTips = () => {
                    console.log("show")
                    informationBtn.classList.add('show')
                    document.body.addEventListener('click', hideTips, true)
                },
                hideTips = () => {
                    console.log("hide")
                    informationBtn.classList.remove('show')
                    document.body.removeEventListener('click', hideTips, true)
                },
                getResults = (searchTeam) => {
                    const r = []
                    for(let i = 0, len = results.length; i < len; i++){
                        const   result = results[i],
                                keywords = result.keywords
                        
                        for(let j = 0, jLen = keywords.length; j < jLen; j++) {
                            const keyword = keywords[j]
                            keyword === searchTeam && r.push(result)
                        }
                    }
                    return r
                }
                
        searchBtn.addEventListener('click', ()=>{
            const   searchTerm = searchField.value.toLowerCase(),
                    isEmpty = searchTerm === '',
                    matches = !isEmpty ? getResults(searchTerm) : [],
                    hasResults = !isEmpty && matches.length > 0,
                    alert = isEmpty ? 'Please enter search term'
                            : !hasResults ? 'No matches for search term provided'
                            : '' 
            
            populateAlert(alert)
            populateResults(matches)
        })

        informationBtn.addEventListener('click', showTips)
    }, [])
            
    

    return (    
        <Layout active='medical-information'
        title='PharmaEssentia Medical Affairs | Medical Information'
        description='Search our medical library to find information relating to PharmaEssentia’s approved products.'
        socialTitle='PharmaEssentia Medical Affairs | Medical Information'
        socialDescription='Search our medical library to find information relating to PharmaEssentia’s approved products.'
        pathname='/medical-information'
        >
            <section>
                <div className='inner'>
                    <h1>Medical Information</h1>
                    {
                        // <SegmentedControl buttons='medical-information' active='search-medical-information'/>
                    }
                    <p>PharmaEssentia Medical Affairs is committed to partnering with healthcare professionals to provide medical and scientific information to support the appropriate use of our products and to help them make informed clinical decisions for the treatment of their patients. Please use the tool below to search our medical information library. By using this website you confirm the unsolicited nature of your request.</p>
                    <p>This information is provided in furtherance of ensuring accurate scientific information is available to healthcare professionals in response to their unsolicited requests, and should not be construed as an endorsement or promotion of indications or uses not discussed in the FDA-approved Prescribing Information. It is intended to provide pertinent data to assist you in forming your own conclusions in order to make healthcare decisions. <span style={{color: "red"}}>A suspected adverse event or product complaint should be reported to</span> <a className='text-link no-break' href="mailto:safety-us@pharmaessentia.com?subject=Report%20adverse%20event%20or%20Complaint">safety-us@pharmaessentia.com</a>. Please review the full Prescribing Information for completeness with attention to the <span style={{fontWeight: "bold", color: "black"}}>INDICATIONS AND USAGE, DOSAGE AND ADMINISTRATION, CONTRAINDICATIONS, WARNINGS AND PRECAUTIONS, and ADVERSE REACTIONS</span> sections prior to use.</p>
                    <div>
                        <p>For additional information, contact the PharmaEssentia Medical Information team.</p>
                        <p>Phone: <a className="text-link no-break" href="tel:+18009992449">1-800-999-2449</a></p>
                        <p>Email: <a className='text-link' href="mailto:medinfo@pharmaessentia-us.com?subject=Request%20for%20more%20information:%20Exceed%20ET%20Clinical%20Trial">medinfo@pharmaessentia-us.com</a></p>
                    </div>
                    <div className='row-bar-left'>
                        <h3 id='information-title'>Search Medical Information</h3>
                        <button id='information'>
                            <img src='/svg/information-icon.svg' alt='Information icon'/>
                            <div id='information-tips'>
                                <span className='triangle'></span>
                                <ul>
                                    <li className='no-list bold'>Search tips:</li>
                                    <li className='no-list'>
                                        <ul>
                                            <li>Select the best search term for the information you are looking for as the results are very specific</li>
                                            <li>Ensure the spelling is correct; use more terms to refine and narrow the search results</li>
                                        </ul>
                                    </li>
                                    
                                </ul>
                            </div>
                        </button>
                    </div>
                    <div id='search-medical-information'>
                        <div className='row'>
                            <input id='search-field' type='text' placeholder='Enter keyword(s)'></input>
                            <button id='search-btn'>Search</button>
                        </div>
                        <p id='search-errors' className='alert'></p>
                    </div>
                    <div id='search-results'>
                        {/*
                        <a 
                        href='#'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img src="/svg/download-icon.svg" alt="Download icon" /><span>SRL #1 - PEGINVERA study evaluating ropeginterferon alfa-2b-njft for polycythemia vera</span></a>
                        <a 
                        href='#'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img src="/svg/download-icon.svg" alt="Download icon" /><span>SRL #2 - Criteria for hematologic response and molecular response in PEGINVERA  study</span></a>
                        <a 
                        href='#'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img src="/svg/download-icon.svg" alt="Download icon" /><span>SRL #5 - Molecular response following ropeginterferon alfa-2b-njft for PV in the PEGINVERA study</span></a>
                        */}
                    </div>
                    <h3>Products</h3>
                    <p>This section contains information relating to PharmaEssentia's approved products.</p>
                    <p className='top-margin'><b>BESREMi<sup>®</sup></b> (ropeginterferon alfa-2b-njft)</p>
                    <p>Please see the full Prescribing Information, including Boxed Warning.</p>
                    <div className='row-to-column gapped'>
                        <a 
                        target='_blank'
                        rel='noopener noreferrer'
                        className='cta-button white-list'
                        href='https://us.pharmaessentia.com/wp-content/uploads/2021/11/BESREMi-USPI-November-2021-1.pdf'>Full Prescribing Information</a>
                        <a 
                        target='_blank'
                        rel='noopener noreferrer'
                        className='cta-button white-list'
                        href='https://us.pharmaessentia.com/wp-content/uploads/2021/11/BESREMi-USPI-November-2021-1.pdf#page=18'>Medication Guide</a>
                        
                    </div>
                    <p className='sml top-margin'>BESREMi is a registered trademark of PharmaEssentia Corporation.</p>
                </div>
            </section>
        </Layout>
    )
}